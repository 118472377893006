@font-face
{
    font-family: 'fontello';
    src: url('../fonts/fontello.eot?44875847');
    src: url('../fonts/fontello.eot?44875847#iefix') format('embedded-opentype'), url('../fonts/fontello.woff2?44875847') format('woff2'), url('../fonts/fontello.woff?44875847') format('woff'), url('../fonts/fontello.ttf?44875847') format('truetype'), url('../fonts/fontello.svg?44875847#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?44875847#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before
{
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}




.icon-right-circle:before
{
    content: '\e800';
}

.icon-emo-happy:before
{
    content: '\e801';
    font-size: 25px;
    margin-right: 0.4em;
}

.icon-user-outline:before
{
    content: '\e802';
    font-size: 25px;
    margin-right: 0.4em;
}

.icon-instagram:before
{
    content: '\f16d';
}

.icon-toggle-off:before
{
    content: '\f204';
    font-size: 25px;
    margin-right: 0.4em;
}

.icon-toggle-on:before
{
    content: '\f205';
    font-size: 30px;
    margin-right: 0.4em;
}

.icon-twitter:before
{
    content: '\f309';
}

@media (max-width:768px)
{
    .icon-twitter:before
    {
        font-size: 20px;
    }
}


.icon-facebook:before
{
    content: '\f30c';
}


.icon-linkedin:before
{
    content: '\f318';
}

@media (max-width:768px)
{
    .icon-linkedin:before
    {
        font-size: 20px;
    }
}

.icon-down-open:before
{
    content: '\e803';
    float: right;
}
/* '' */
.icon-up-open:before
{
    content: '\e804';
    float: right;
}
/* '' */
