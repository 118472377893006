*{
  box-sizing:border-box;
}

#root
{
  width: 100%;
}

html
{
  width: 100vw;
}

body
{
  width: 100%;
  background-color: #000;
  color: #000;
  font-family: Marmelad;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

form
{
  width: 100%;
}

header
{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  color: #fff;
}

@media (max-width: 768px)
{
  header
  {
      margin-top: 10px;
  }
}

.row
{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}

.row.site-header
{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px)
{
  .row.site-header
  {
      justify-content: center;
      align-items: center;
  }
}

.site-logo
{
  width: 25%;
}


@media (max-width: 886px)
{
  .site-logo
  {
      width: 25%;
  }
}


.site-cover
{
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 886px) 
{
  .site-cover
  {
      width: 52%;
  }

  .site-cover h1
  {
      width: 100%;
  }
}

p {
  display: block;
  position: relative;
}

a
{
  color: #000;
  text-decoration: none;
}

a.underline {
  text-decoration: underline;
}

a:hover
{
  text-decoration: underline;
  color: #000;
}


.site-social
{
  width: 25%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 30px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 886px) 
{
  .site-social
  {
      width: 20%;
      margin: 0;
      padding: 0;
      margin-top: 1.5rem;
  }
}


.site-social a
{
  color: #fff;
}

.site-logo img
{
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

@media (max-width: 768px) 
{
  .site-logo img
  {
      width: 90px;
      height: 90px;
  }
}


h1
{
  font-family: 'Trade Winds';
  font-size: 60px;
  text-align:center;
}

@media (max-width: 886px)
{
  h1
  {
      font-size: 20px;
      margin:0;
      padding:0;
  }
}

nav
{
  text-align: center;
  width: 100%;
  font-size: 25px;
  border: 1px solid white;
  /*box-shadow: 0 -2px 5px purple;*/
  box-shadow: 0 -2px 5px 0 purple, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
}

@media (max-width: 768px)
{
  nav
  {
      font-size: 18px;
  }
}

@media (min-width: 1024px) {
  nav {
    font-size: 20px;
  }
}

@media (min-width: 1680px) {
  nav {
    font-size: 25px;
  }
}
nav a
{
  color: #fff;
  text-decoration: none;

}

nav a:hover
{
  color: #fff;
}

nav ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li
{
  list-style: none;
  display: inline-block;
  width: auto;
    margin-right: 2rem;
  }
  
  @media (min-width: 1280px) {
    nav ul li {
      margin-right: 3rem;
    }
  }
  
  @media (min-width: 1366px) {
    nav ul li {
      margin-right: 4rem;
    }
  }
  
  @media (min-width: 1680px) {
    nav ul li {
      margin-right: 4.5rem;
    }
  }
  
  nav ul li:last-child {
    margin-right: 0;
}

main
{
  width: 100%;
  background-color: #fff;
  min-height: 70vh;
  padding-bottom: 20px;
}

footer
{
  width: 100%;
  background-color: #000;
  height: 5%;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}

h2
{
  padding: 30px;
  text-align: center;
  margin:0;
  font-size: 1.5em;
}

@media (max-width: 768px)
{
  h2
  {
      font-size: 1.1em;
  }
}

#content, #homeContent
{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}


@media (max-width: 768px) 
{
  #content
  {
      width: 80%;
  }

  #homeContent
  {
      width: 100%;
      padding: 1rem;
  }
}

#homePageIntro {
  width: 100%;
  margin: 2rem 0;
  font-size: 1.2rem;
  text-align: center;
}

.col-left
{
  width: 55%;
}

@media (max-width: 768px) 
{
  .col-left
  {
      width: 100%;
  }
}



.col-right
{
  width: 35%;
}

@media (max-width: 768px) 
{
  .col-right
  {
      width: 100%;
      margin-top: 2rem;
  }
}




.col-full
{
  width: 100%;
}

#profile-image
{
  display: flex;
  height: 156px;
  background-color: #000;
}

#banner-image
{
  display: flex;
  height: 156px;
  background-color: #000;
}

#profile-image img
{
  width: 100%;
  flex: 3.5498721228;
  object-fit: contain;
}

#profile-details a
{
  color: #000;
}

#profile-details
{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1920px) 
{
  #profile-details
  {
      width: 90%;
  }
}


.col-left img
{
  width: 100%;
  flex: 3.5498721228;
  object-fit: cover;
  object-position: 100% 25%;
}

@media (min-width: 992px)
{
  .col-left img
  {
      object-position: 100% 62%;
  }
}

.col-full
{
  margin-top: 30px;
}

.col-full img
{
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  object-position: 100% 56%;
}

.social-content
{
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width:768px)
{
  .social-content
  {
      padding: 0;
  }
}

#about-nav
{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width:768px)
{
  #about-nav
  {
      text-align: center;
      font-size: 15px;
  }
}

#toggle
{
  text-align: right;
  cursor:pointer;
}

@media (max-width: 768px)
{
  #toggle h3
  {
      font-size: 0.8em
  }
}



h3
{
  display: inline-block;
  font-size: 1em;
}

#pro h3
{
  cursor:pointer;
}

#personable h3
{
  cursor:pointer;
}

@media (max-width: 768px)
{
  #professional h3, #personable h3
  {
      font-size: 0.8em
  }
}


#divProfessional h3, #divPersonable h3
{
  cursor: pointer;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
}

#toggle-subs
{
text-align: center;
cursor:pointer;
font-weight: bold;
}

.socialToggle
{
  cursor: pointer;
}

.active
{
  text-decoration:underline;
}


#profile-content ul
{
  margin: 0;
  padding: 0.5em;
}

#social-content a div
{
  line-height: 30px;
}


#cards
{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 3rem 0;
  justify-content: space-between;
}

.card
{
  text-align: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto 2rem auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.5rem;
  cursor: pointer;
}

@media (min-width: 992px)
{
  .card
  {
      width: 30%;
  }
}

.cardTitle {
  padding: 0.5rem 0;
  text-decoration: underline;
}

.cardContent {
  margin: 0 0 1rem 0;
}

#professionalContent,
#communityContent,
#artContent,
#craftContent,
#photoContent,
#funContent
{
  font-size: 1rem;
}

.card img {
  width: 100%;
  height: auto;
}
.col-right img
{
max-width: 100%;
}

#trainingProTitle, #trainingOtherTitle
{
  cursor: pointer;
  text-align: left;
}

#trainingProTitle h2, #trainingOtherTitle h2
{
  text-align: left;
  padding: 0;
}

#siteMenu
{
  /*padding: 0 1rem;*/
  font-size: 1rem;
  color: #fff;
  position: relative;
  /*top: 60px;
  left: 0;*/
  z-index: 999;
  height: 28px;
  width: 28px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media (min-width: 769px)
{
  #siteMenu
  {
      display: none
  }
}

#siteMenu span, #siteMenu span:before, #siteMenu span:After
{
  position: absolute;
  content: '';
  width: 28px;
  height: 2.5px;
  background: #fafafa;
  border-radius: 20px;
  transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
}

#siteMenu span::before
{
  top: -8px;
}

#siteMenu span::after
{
  top: 8px;
}

#siteMenu.active > span
{
  background: transparent;
}

#siteMenu.active > span::before, #siteMenu.active > span::after
{
  background: #361e54;
  top: 0px;
}

#siteMenu.active > span::before
{
  transform: rotate(-225deg);
}

#siteMenu.active > span::after
{
  transform: rotate(225deg);
}


#site-navigation
{
  display: flex;
}

@media (max-width: 768px)
{
  #site-navigation
  {
      padding: 6rem 1rem;
      justify-content: flex-start;
      position: absolute;
      left: -200%;
      top: 0;
      z-index: 998;
      color: #005c9c;
      background: #fff;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      transition: 0ms opacity cubic-bezier(0.77, 0, 0.175, 1);
      opacity: 0;
  }
}

#site-navigation.active
{
  left: 0;
  opacity: 1;
  transition: 600ms opacity cubic-bezier(0.77, 0, 0.175, 1);
}

@media(max-width: 991px)
{
  #site-navigation nav ul li
  {
      width: 100%;
      height: 3rem;
  }

  #site-navigation nav ul li a
  {
      color: #000;
  }
}

.gallery
{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: space-between;
}

.galleryHeading
{
  background-color: #000;
  color: #fff;
  padding: 0 0.5rem;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.galleryDescription
{
  width: 100%;
}

.galleryItem
{
  width: 48%;
  margin: 1rem 0;
}

.galleryTitle 
{
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.galleryImage
{
  width: 100%;
  height: auto;
}
.highlight {
  font-family: Acme, sans-serif;
  font-size: 2rem;
}

#photoPage img:not(.socialIcon) {
  width: 8rem;
  height: auto
}

img.socialIcon {
  width: 32px;
  height: auto;
}

img.gcLogo {
  width: 12rem;
  height: auto;
}

h2.h2Fun {
  text-align: left;
  padding: 30px 0;
}

.funItem {
  width: 100%;
  margin-bottom: 2rem;
}

.funItem img {
  width: 20rem;
  height: auot;
}


.floatingchat-container, .floatingchat-container-mobi {
    width: 210px !important;
}
